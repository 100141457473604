import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import { useLocale } from "../context/LocaleContext";
import UsersList from "../components/UsersList";

export default function Users() {
  const { user } = useUser();
  const locale = useLocale();

  if (!user.isAdmin) {
    return <Navigate to={`${locale}/profile`} />;
  }

  return <UsersList />;
}
