import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Card from "../Card";
import translateKey from "../../utils/translateKey";
import VideoCard from "../VideoCard";

const GridContentsView = ({ contents }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2 p-page">
      {contents.map((content, i) => (
        <div key={content.id}>
          {content.values.attribute_video_link?.[0]?.data ? (
            <VideoCard content={content} />
          ) : (
            <Card
              title={translateKey(content.names)}
              image={`${content.values.attribute_Fichier[0].data.url}?preview=1`}
              actions={
                <button
                  className="flex items-center gap-2 uppercase font-lacosteExpandedBold hover:underline cursor-pointer text-primary"
                  onClick={() =>
                    window.open(
                      `${content.values.attribute_Fichier[0].data.url}?download=1`
                    )
                  }
                  type="button"
                >
                  <span> {t("download")} </span>
                  <div className="text-primary">
                    <svg
                      width={20}
                      height={20}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <title>Download icon</title>
                      <path
                        fill="currentColor"
                        d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z"
                      />
                    </svg>
                  </div>
                </button>
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};

GridContentsView.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      cover: PropTypes.string,
      file: PropTypes.string,
    })
  ),
};

export default GridContentsView;
