const HeaderButton = ({ src, count }) => {
  return (
    <div className={"relative p-2 hover:bg-primary-100 duration-75"}>
      {count > 0 && (
        <span className="absolute flex justify-center items-center text-[0.5rem] -top-0 -right-0 bg-red-500 rounded-full font-bold text-white w-4 h-4">
          {count}
        </span>
      )}
      <img src={src} width={30} alt="icon" />
    </div>
  );
};

export default HeaderButton;
