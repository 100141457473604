import { useTranslation } from "react-i18next";
import Carousel from "../Carousel";
import translateKey from "../../utils/translateKey";

const ContentsView = ({ items, onClickItem }) => {
  const { t } = useTranslation();

  const getMedias = contentGroup => {
    return contentGroup.contents.map(content => ({
      image: content.values.content_image?.[0].data.url, // @todo should be localized
      name: translateKey(content.names) !== "-" ? translateKey(content.names) : "",
      info: content.values.content_description?.[0].data || null, // @todo should be localized
      price: content.values.attribute_prix?.[0].data || null, // @todo should be localized
      refGroups: content.values.attribute_group_ref?.[0].data ? JSON.parse(content.values.attribute_group_ref?.[0].data) : [], // @todo should be localized
      data: content.values.attribute_materials?.[0].data ? JSON.parse(content.values.attribute_materials?.[0].data) : [], // @todo should be localized
    }));
  }

  return (
    <div className="flex flex-col gap-4">
      {items?.length > 0 ? (
        items.map((contentGroup, i) => (
          <Carousel
            key={i}
            title={contentGroup.title ?? ""}
            medias={getMedias(contentGroup)}
            onClickItem={onClickItem}
          />
        ))
      ) : (
        <span> {t("no_contents")} </span>
      )}
    </div>
  );
};

export default ContentsView;
