import PropTypes from "prop-types";
import React, { useState } from "react";

const NavbarItem = ({ label, onClick, isActive, color = "var(--primary)" }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={
        "font-lacosteExpandedBold relative navbar-main-item text-sm lg:text-md"
      }
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <button
        className={`flex items-center justify-between w-max uppercase px-6 py-3 ${
          isActive || isHover ? "border-b-2" : ""
        }`}
        style={{
          borderColor: isActive || isHover ? color : "",
          color: isActive || isHover ? color : "",
        }}
        onClick={() => onClick()}
        type="button"
      >
        {label}
      </button>
    </div>
  );
};

NavbarItem.propTypes = {
  label: PropTypes.string.isRequired,
};

export default NavbarItem;
