import { useTranslation } from "react-i18next";
import Input from "../components/Inputs/Input";
import http from "../utils/http.utils";
import toastr from "../utils/toast.utils";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import Loader from "../components/Loader";
import { useLocale } from "../context/LocaleContext";
import ImageBackground from "../assets/images/bg-login.jpg";

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useLocale();

  const [token, setToken] = useState(null);
  const [values, setValues] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [error, setError] = useState(null);

  const params = useParams();

  const welcome = !!params.welcome;

  const fetchToken = async () => {
    try {
      const token = await http.get(
        `/api/password-reset-tokens/${params.token}`
      );
      setToken(token);
    } catch (error) {
      toastr.error(t("password_reset_invalid_token"));
      navigate(`/${locale}/forgot_password`);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  const onChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (values.new_password !== values.confirm_password) {
        toastr.error(t("passwords_not_match"));
        setError(null);
      } else {
        await http.post("/api/password-resets", {
          token: token.token,
          password: values.new_password,
        });
        toastr.success(t("password_updated"));
        navigate("/login");
      }
    } catch (error) {
      setError(error);
      toastr.error(t("password_update_failed"));
    }
  };

  return (
    <section
      className="h-full"
      style={{
        backgroundImage: `url(${ImageBackground})`,
        backgroundSize: "cover",
      }}
    >
      <div className="h-full flex justify-center items-center">
        <div className="bg-white p-10 flex flex-col items-center justify-center gap-2 w-[400px]">
          <Logo />
          <h1 className="uppercase font-lacosteExpandedBold mb-10">
            {t("app_name")}
          </h1>
          <h2 className="self-start">
            {t(welcome ? "set_password" : "reset_password")}
          </h2>
          {token ? (
            <>
              <p className="text-sm text-secondary mb-6">
                {t(
                  welcome ? "set_password_help" : "reset_password_help"
                ).replace("%email%", token.email)}
              </p>
              <form
                onSubmit={onSubmit}
                className="w-full flex flex-col justify-center gap-4"
              >
                <div>
                  <label htmlFor={"new_password"}>{t("new_password")}</label>
                  <Input
                    id={"new_password"}
                    type={"password"}
                    required
                    onChange={onChange}
                  />
                </div>
                <div>
                  <label htmlFor={"confirm_password"}>
                    {t("confirm_password")}
                  </label>
                  <Input
                    id={"confirm_password"}
                    type={"password"}
                    required
                    onChange={onChange}
                    className={error ? "ring-red-700" : "ring-gray-300"}
                  />
                  {error
                    ? error.violations.map((violation, i) => (
                        <p key={i} className="text-xs text-red-700 mb-0 mt-0">
                          {violation.message}
                        </p>
                      ))
                    : null}
                </div>
                <button
                  type="submit"
                  className="flex justify-center items-center gap-2 border py-2 w-full hover:bg-slate-50 transition mb-3 uppercase"
                >
                  {t("send")}
                </button>
              </form>
            </>
          ) : (
            <Loader />
          )}
          <div className="flex justify-center items-center w-full">
            <hr className="w-3/4 flex justify-center" />
          </div>
          <div className="flex justify-center w-full">
            <Link
              to="/login"
              className="text-sm hover:underline hover:cursor-pointer "
            >
              {t("cancel")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
