class Locale {

    constructor() {
        this.locale = 'en_US';
    }

    setLocale(newLocale) {
        this.locale = newLocale;
    }

    getLocale() {
        return this.locale;
    }

}

const locale = new Locale();

export default locale;
