import { isDesktop, isMobile } from "../../utils/style.utils";
import { getChildrens, getId, hasChildrens } from "../../utils/tree.utils";
import PropTypes from "prop-types";
import { createContext, useState } from "react";
import Slider from "react-slick";
import NavbarDropdown from "./components/NavbarDropdown/NavbarDropdown";
import NavbarItem from "./components/NavbarItem/NavbarItem";
import "./Navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocale } from "../../context/LocaleContext";
import translateKey from "../../utils/translateKey";

export const NavbarContext = createContext();

const Navbar = ({ nav, items, route }) => {
  const [tabIsOpen, setTabIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const locale = useLocale();
  const searchParams = new URLSearchParams(location.search);

  const handleNav = (item) => {
    const params = new URLSearchParams(searchParams);
    params.set("category", item.id);
    if (params.get("category")) {
      navigate(`/${locale}/${route}/${nav.id}?${params.toString()}`);
    }
  };

  const settings = {
    speed: 500,
    infinite: false,
    slidesToScroll: 4,
    variableWidth: true,
    className: "z-[2]",
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleLoad = () => {
    const el = document.getElementsByClassName("slick-list")[0];
    el.style.overflow = "visible";
  };

  return (
    <NavbarContext.Provider value={{ tabIsOpen, setTabIsOpen }}>
      <nav
        className={`relative navbar ${
          tabIsOpen ? "overflow-visible" : "overflow-hidden"
        }`}
      >
        <div className="border-b">
          <Slider {...settings} onReInit={handleLoad}>
            {items?.map((item, i) =>
              hasChildrens(item, items) &&
              nav["@id"] === item.parent &&
              isDesktop() ? (
                <NavbarDropdown
                  key={i}
                  label={translateKey(item.names)}
                  items={getChildrens(getId(item.id), items)}
                  isActive={getChildrens(getId(item.id), items).some(
                    (child) => getId(child.id) === +searchParams.get("category")
                  )}
                  onClickChild={(child) => handleNav(child)}
                  onClick={() => isMobile() && handleNav(item)}
                />
              ) : (
                nav["@id"] === item.parent && (
                  <NavbarItem
                    key={i}
                    label={translateKey(item.names)}
                    isActive={item.id === +searchParams.get("category")}
                    onClick={() => handleNav(item)}
                  />
                )
              )
            )}
          </Slider>
        </div>
      </nav>
    </NavbarContext.Provider>
  );
};

Navbar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      parent: PropTypes.string,
    })
  ),
};

export default Navbar;
