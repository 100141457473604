import Header from "../Header/Header";
import IconCross from "../../assets/icons/cross.svg";
import LogoLacoste from "../../assets/icons/logo_lacoste.svg";
import IconMenu from "../../assets/icons/menu.svg";
import { Link } from "react-router-dom";
import { useLocale } from "../../context/LocaleContext";

const HeaderDrawer = ({ drawerIsOpen, toggleDrawer }) => {
  const locale = useLocale();
  return (
    <>
      <div className="sm:hidden">
        <Header
          drawerIsOpen={drawerIsOpen}
          toggleDrawer={() => toggleDrawer(!drawerIsOpen)}
        />
      </div>
      <div className="hidden sm:block lg:hidden py-page">
        <div className="flex items-center gap-4">
          <button
            type="button"
            onClick={toggleDrawer}
            className="flex items-center"
          >
            <img
              src={IconCross}
              alt="menu"
              className={`${
                drawerIsOpen ? "translate-x-4 w-8" : "-translate-x-20"
              } transition-all duration-300 ease-in-out hover:bg-primary-light rounded-full`}
            />
            <img
              src={IconMenu}
              alt="menu"
              className={`${
                !drawerIsOpen ? "translate-x-0 w-8" : "-translate-x-20"
              } transition-all duration-300 ease-in-out hover:bg-primary-light rounded-full`}
            />
          </button>
          <Link
            to={`/${locale}/`}
            className="flex items-center gap-3 w-48 md:w-64"
          >
            <img src={LogoLacoste} alt="logo-lacoste" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default HeaderDrawer;
