import logo from "../assets/icons/logo_lacoste.svg";
import logoWhite from "../assets/icons/logo_lacoste_blanc.svg";

const Logo = ({ white = false, className }) => {
  return (
    <div
      className={
        "flex items-center gap-3 text-3xl  font-bold " + className
      }
    >
      <img src={white ? logoWhite : logo} alt="Logo Lacoste" />
    </div>
  );
};

export default Logo;
