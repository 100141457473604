import { Link, useLocation } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";

export default function AsideNav() {
  const location = useLocation();
  const locale = useLocale();

  const links = [
    {
      name: "Profile",
      url: "/profile",
    },
    {
      name: "Stats",
      url: "/stats",
    },
    {
      name: "Users",
      url: "/users",
    },
  ];
  return (
    <aside className="w-64 h-full">
      {links.map((link) => (
        <Link
          key={link.url}
          to={`/${locale}${link.url}`}
          className={`block py-2 px-4 text-gray-700 transition-all ${
            location.pathname === `/${locale}${link.url}`
              ? "bg-primary text-white "
              : "hover:bg-primary-100"
          }`}
        >
          {link.name}
        </Link>
      ))}
    </aside>
  );
}
