import { useRef } from "react";
import PropTypes from "prop-types";

const DropdownItem = ({ id, content, onClick }) => {
  const ref = useRef(null);
  return (
    <li
      id={`menu-item-${id}`}
      ref={ref}
      onClick={() => (onClick ? onClick(ref.current) : null)}
    >
      {content}
    </li>
  );
};

DropdownItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

export default DropdownItem;
