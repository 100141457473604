import iconLacoste from "../assets/icons/logo_lacoste.svg";

const Loader = () => {
  return (
    <div className="relative w-[20rem] h-[20rem]">
      <div className="animate-pulse text-primary h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <svg
          className="animate-spin"
          width="200"
          height="200"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Loader</title>
          <circle
            cx="50"
            cy="50"
            r="40"
            stroke="none"
            strokeWidth="8"
            fill="none"
          />
          <path
            d="M50 10 A 40 40 0 0 1 90 50"
            stroke="currentColor"
            strokeWidth="8"
            fill="none"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center animate-pulse">
        <img src={iconLacoste} alt="import" width={120} />
      </div>
    </div>
  );
};

export default Loader;
