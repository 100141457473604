import { useState, useContext } from "react";
import { NavbarContext } from "../../Navbar";
import { Link, useParams } from "react-router-dom";
import { useLocale } from "../../../../context/LocaleContext";
import translateKey from "../../../../utils/translateKey";

const NavbarDropdown = ({
  label,
  items,
  isActive,
  color = "var(--primary)",
}) => {
  const [open, setOpen] = useState(false);
  const context = useContext(NavbarContext);

  const handleHover = () => {
    if (context) {
      if (context.tabIsOpen === false) context.setTabIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setOpen(false);
    if (context) {
      if (context.tabIsOpen === true) context.setTabIsOpen(false);
    }
  };

  return (
    <div
      className={"font-lacosteExpandedBold relative z-[1000] navbar-main-item"}
      onMouseLeave={() => handleMouseLeave()}
      onMouseEnter={() => setOpen(true)}
      onMouseMove={() => handleHover()}
    >
      <button
        id="dropdownNavbarLink"
        className={`flex items-center justify-between w-max uppercase text-sm lg:text-md px-6 py-3 ${
          open || isActive ? "border-b-2" : ""
        }`}
        style={{
          borderColor: open || isActive ? color : "",
          color: open || isActive ? color : "",
        }}
        type="button"
      >
        {label}
        <svg
          className={`w-2.5 h-2.5 ml-2.5 transition duration-300 hidden md:block ${
            open ? "rotate-180" : ""
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <div
        className={`z-50 w-max lg:min-w-[10rem] font-normal bg-white divide-y divide-gray-100 border dark:divide-gray-600 absolute duration-300 ${
          open ? "block" : "hidden"
        }`}
      >
        <ul
          className="py-2 text-sm relative h-full"
          aria-labelledby="dropdownLargeButton"
        >
          {items.map((item, i) => (
            <Children key={i} child={item} color={color} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const Children = ({ child, color }) => {
  const params = useParams();
  const locale = useLocale();

  return (
    <li
      className={
        "bg-opacity-5 duration-75 transition-all hover:bg-primary-light"
      }
    >
      <Link
        to={`/${locale}/nav/${params.nav}?category=${child.id}`}
        className="block px-4 py-2 transition duration-75 uppercase"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {translateKey(child.names)}
      </Link>
    </li>
  );
};

export default NavbarDropdown;
