import http from "../utils/http.utils";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import Loader from "../components/Loader";
import translateKey from "../utils/translateKey";
import Header from "../components/ContentCategory/Header";

export default function CalendarsPage() {
  const locale = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [calendars, setCalendars] = useState(null);
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState(null);
  const [parentFolder, setParentFolder] = useState("");

  const fetchCalendars = async () => {
    const leaves = await http.get(`/api/leaves/${process.env.REACT_APP_CALENDARS_FOLDER_ID}`);
    setParentFolder(translateKey(leaves.category.names));
    setCalendars(leaves.contents);
    navigate(`/${locale}/calendars?id=${leaves.contents[0].id}`);

  };

  const fetchCalendar = async () => {
    setLoading(true);
    try {
      const data = await http.get(`/api/flat-contents/${id}`);
      const media = data.values.attribute_Fichier?.[0].data.url;
      setCalendar({ ...data, media });
    } catch (error) {
      console.error("Error fetching calendar:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickCalendar = async (idParam) => {
    navigate(`/${locale}/calendars?id=${idParam}`);
  };

  useEffect(() => {
    fetchCalendars();
  }, []);

  useEffect(() => {
    if (id) fetchCalendar();
  }, [id]);

  return (
    <div className="h-full w-full flex flex-col">
      <Header
        title={parentFolder}
        handleBack={() => navigate(`/${locale}/`)}
      />
      {/* <div className={!id ? "border-b" : ""}> */}
      <div className="border-b">
        {calendars ? (
          calendars.map((calendar, i) => (
            <button
              className={`font-lacosteExpandedBold text-sm lg:text-md w-max uppercase px-6 py-3 hover:text-primary transition-colors ${
                +id === calendar.id
                  ? "border-b-2 border-primary text-primary"
                  : ""
              } `}
              key={calendar.id}
              type="button"
              onClick={() => handleClickCalendar(calendar.id)}
            >
              {translateKey(calendar.names)}
            </button>
          ))
        ) : (
          <div className="flex min-w-full h-full justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
      {calendar ? (
        !loading ? (
          calendar.media && (
            <iframe
              title={calendar.media}
              src={calendar.media}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
            />
          )
        ) : (
          <Loader />
        )
      ) : null}
    </div>
  );
}
