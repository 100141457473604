import { useLocation, useNavigate } from "react-router-dom";
import http from "../utils/http.utils";
import { useEffect } from "react";
import { useLocale } from "../context/LocaleContext";

export default function LoginAfterPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const locale = useLocale();
  const searchParams = new URLSearchParams(location.search);
  const params = new URLSearchParams(searchParams);
  const returnURL = searchParams.get("returnBack");

  useEffect(() => {
    const token = params.get("token");
    if (token) {
      http.setToken(token);
    }

    returnURL
      ? navigate(decodeURIComponent(returnURL))
      : navigate(`/${locale}/`);
  }, []);

  return <div />;
}
