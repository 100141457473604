import { useTranslation } from "react-i18next";
import chevronLeft from "../../assets/icons/chevron-left.svg";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import { useLocale } from "../../context/LocaleContext";

const Header = ({ title, handleBack }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  return (
    <div className="flex min-h-12 bg-primary relative justify-center items-center py-2 text-white font-lacosteExpandedBold uppercase font-bold px-page">
      <div className="absolute left-2">
        <div
          onClick={() => handleBack()}
          className="flex items-center gap-1 cursor-pointer"
        >
          <span>
            <img src={chevronLeft} width={25} alt="chevron-left-icon" />
          </span>
          <span className="hidden text-sm md:block">{t("back")}</span>
        </div>
      </div>
      <div className="max-w-[50vw]">
        <p className="truncate text-xs md:text-sm lg:text-2xl">{title}</p>
      </div>
      <Link to={`/${locale}/`} className="absolute right-2 lg:block">
        <Logo white className={"text-white text-[1.3rem]"} width={40} />
      </Link>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  handleBack: PropTypes.func,
};

export default Header;
