import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import translateKey from "../utils/translateKey";

const Breadcrumbs = ({ showTitle, items }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useLocale();
  const breadcrumbRef = useRef(null);
  const lastItem = items[items.length - 1];

  const isLast = (index) => {
    return index === items.length - 1;
  };

  useEffect(() => {
    if (breadcrumbRef.current) {
      breadcrumbRef.current.scrollLeft = breadcrumbRef.current.scrollWidth;
    }
  }, [items]);

  const handleNav = (item) => {
    if (item.id === +`${process.env.REACT_APP_CX_ID}`) {
      navigate(`/${locale}/`);
    }
    if (item.id) {
      navigate(`/${locale}/?nav=${item.id}`);
    }
    if (item.link) {
      navigate(`/${locale}${item.link}`);
    }
  };

  return (
    <div id="breadcrumbs-container">
      <div
        ref={breadcrumbRef}
        className="flex gap-2 text-xs overflow-auto hide-scrollbar"
      >
        {items.length > 0 && (
          <>
            <div
              className="flex gap-2 cursor-pointer"
              onClick={() => navigate(`/${locale}/`)}
            >
              <span className="uppercase text-secondary ">{t("home")}</span>
              <span className="text-secondary"> {">"} </span>
            </div>
            {items.map((item, index) => (
              <div
                key={index}
                className={`flex gap-2 ${item.link ? "cursor-pointer" : ""}`}
                onClick={() => (!isLast(index) ? handleNav(item) : null)}
              >
                <span
                  className={`uppercase w-max ${
                    !isLast(index)
                      ? "text-secondary hover:text-gray-700 w-max  cursor-pointer"
                      : "font-lacosteExpandedBold"
                  }`}
                >
                  {item.name || translateKey(item.names)}
                </span>
                {!isLast(index) && (
                  <span className="text-secondary">{">"}</span>
                )}
              </div>
            ))}
          </>
        )}
      </div>
      {showTitle && (
        <div className="font-lacosteExpandedBold text-xl md:text-2xl uppercase my-2">
          {lastItem.name || translateKey(lastItem.names)}
        </div>
      )}
    </div>
  );
};

Breadcrumbs.propTypes = {
  showTitle: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
    })
  ).isRequired,
};

export default Breadcrumbs;
