/**
 *
 * @param {number} id id of the current item
 * @param {string|null} attribute attribute you want to get
 * @param {array} treeParams your tree
 * @returns array of the tree
 */

export const getTree = (id, attribute, treeParams) => {
    const tree = treeParams ?? JSON.parse(localStorage.getItem('navs')) ?? [];

    const arbo = [];
    const findParent = (id) => {
        tree.forEach(item => {
            if (id === getId(item.id)) {
                arbo.push(attribute ? item[attribute] : item);

                if (item.parent) {
                    findParent(getId(item.parent));
                }
            }
        });
    };

    if (tree) {
        findParent(id);
    }

    return arbo.reverse();
};

export const getChildrens = (parentId, tree) => {
    parentId = getId(parentId);
    if (tree) {
        return tree.filter((t) => parentId === getId(t.parent));
    }

    return false;
};

export const getId = (id) => {
    if (!id) {
        return 0;
    }
    if ('number' === typeof id) {
        return id;
    }

    id = id.toString();
    if (id.includes('/')) {
        return +parseInt(id.split('/').pop());
    }

    return +parseInt(id);
};

export const hasChildrens = (item, tree) => {
    if (!tree || !item) {
        return false;
    }

    return getChildrens(item.id, tree).length > 0;
};
