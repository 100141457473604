import { useTranslation } from "react-i18next";
import Breadcrumbs from "../components/Breadcrumbs";
import DrawerMenu from "../components/DrawerMenu/DrawerMenu";
import Header from "../components/Header/Header";
import Input from "../components/Inputs/Input";
// import Toggle from "../components/Inputs/Toggle";
import Loader from "../components/Loader";
import http from "../utils/http.utils";
import toastr from "../utils/toast.utils";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import AsideNav from "../components/AsideNav";

export default function ProfilePage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useLocale();

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleNav = (item) => {
    if (item) {
      const params = new URLSearchParams(searchParams);
      params.set("nav", item.id);
      navigate(`/?${params.toString()}`);
    } else {
      navigate(`/${locale}/`);
    }
  };

  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const data = await http.get("/api/users/0");
      setUser(data);
    } catch (error) {
      navigate(`/${locale}/logout`);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const [values, setValues] = useState({
    actual_password: "",
    new_password: "",
    confirm_password: "",
  });

  const onChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const [loading, setLoading] = useState(false);
  const [errorInput, setErrorInput] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (values.new_password !== values.confirm_password) {
      toastr.error(t("passwords_not_match"));
      setErrorInput(true);
      return;
    }

    try {
      await http.post("/api/password-updates", {
        token: localStorage.getItem("token"),
        oldPassword: values.actual_password,
        newPassword: values.new_password,
      });
    } catch (error) {
      console.error("API request failed:", error);
      toastr.error(t("Erreur lors de la mise à jour du mot de passe"));
      setLoading(false);
    } finally {
      setLoading(false);
      toastr.success(t("password_updated"));
      setErrorInput(false);
      setValues({
        actual_password: "",
        new_password: "",
        confirm_password: "",
      });
    }
  };

  return (
    <div className="h-full flex flex-col ">
      <Header
        drawerIsOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <div className="grow flex overflow-hidden">
        <DrawerMenu
          isOpen={openDrawer}
          setIsOpen={setOpenDrawer}
          handleNav={handleNav}
        />
        <div className="w-full">
          <hr />
          <div className="m-4 flex justify-between items-end md:items-center">
            <div>
              <div className="mb-2">
                <Breadcrumbs items={[{ name: "profile" }]} showTitle />
              </div>
            </div>
          </div>
          <hr />
          <section className="flex w-full">
            <AsideNav />
            <div className="flex items-center w-full">
              {user ? (
                <div className="flex flex-col gap-16 w-full md:w-1/2 p-10">
                  <section className="grid grid-cols-2 gap-4">
                    <h3 className="col-span-2 text-2xl uppercase font-lacosteExpandedBold">
                      {t("user_profile")}
                    </h3>
                    <div>{t("email")}</div>
                    <Input
                      type="text"
                      disabled
                      value={user?.email}
                      className="text-secondary"
                    />
                    <span>Admin</span>
                    <div className="flex items-center">
                      {/* <Toggle disabled checked={user?.isAdmin} /> */}
                      <span
                        className={`${
                          user?.isAdmin ? "bg-primary/50" : "bg-red-700/50"
                        } text-white px-3 rounded-full`}
                      >
                        {user?.isAdmin ? t("yes") : t("no")}
                      </span>
                    </div>
                  </section>
                  {!user?.sso ? (
                    loading ? (
                      <Loader />
                    ) : (
                      <section className="flex flex-col gap-4">
                        <h3 className="col-span-2 text-2xl uppercase font-lacosteExpandedBold">
                          {t("change_password")}
                        </h3>
                        <div className="col-span-2 max-w-xs flex justify-center w-full">
                          <form
                            onSubmit={onSubmit}
                            className="w-full flex flex-col justify-center gap-4"
                          >
                            <div>
                              <label htmlFor={"actual_password"}>
                                {t("actual_password")}
                              </label>
                              <Input
                                id={"actual_password"}
                                type={"password"}
                                required
                                value={values.actual_password}
                                onChange={onChange}
                              />
                            </div>
                            <div>
                              <label htmlFor={"new_password"}>
                                {t("new_password")}
                              </label>
                              <Input
                                id={"new_password"}
                                type={"password"}
                                required
                                value={values.new_password}
                                onChange={onChange}
                              />
                            </div>
                            <div>
                              <label htmlFor={"confirm_password"}>
                                {t("confirm_password")}
                              </label>
                              <Input
                                id={"confirm_password"}
                                type={"password"}
                                required
                                value={values.confirm_password}
                                onChange={onChange}
                                className={
                                  errorInput ? "ring-red-700" : "ring-gray-300"
                                }
                              />
                            </div>
                            <button
                              type="submit"
                              disabled={loading}
                              className="flex justify-center items-center gap-2 border py-2 w-full hover:bg-slate-50 transition mb-3 uppercase"
                            >
                              {t("send")}
                            </button>
                          </form>
                        </div>
                      </section>
                    )
                  ) : null}
                </div>
              ) : (
                <div className="w-full flex justify-center">
                  <Loader />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
