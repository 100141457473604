import PropTypes from "prop-types";

const DrawerTab = ({ label, onClick, icon, className }) => {
  return (
    <div
      className={`flex items-center gap-2 text-primary cursor-pointer p-4 border-b hover:bg-primary-100 font-lacosteExpandedBold uppercase ${className}`}
      onClick={() => onClick()}
    >
      {icon}
      <span>{label}</span>
    </div>
  );
};

DrawerTab.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node,
  className: PropTypes.string,
};

export default DrawerTab;
