import { t } from "i18next";
import translateKey from "../utils/translateKey";
import whitePlayIcon from "../assets/icons/picto-play-white.svg";

const VideoCard = ({ content }) => {
  const title = translateKey(content.names);
  return (
    <div className="border flex flex-col gap-2 items-center">
      <div className="w-full h-40 flex justify-center items-center bg-primary-400 border border-primary">
        <div className="relative h-full w-full flex justify-center items-center bg-primary text-white">
          <img src={whitePlayIcon} alt="play icon" className="h-10 w-10" />
        </div>
      </div>
      <p className="text-center truncate uppercase  max-w-full px-2">
        {title}
      </p>
      <div className="p-2">
        <button
          className="flex items-center gap-2 uppercase font-lacosteExpandedBold hover:underline cursor-pointer text-primary"
          onClick={() => {
            const videoLink = content.values.attribute_video_link?.[0]?.data;
            window.open(videoLink, "_blank");
          }}
          type="button"
        >
          <span>{t("download")}</span>
          <div className="text-primary">
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>Download icon</title>
              <path
                fill="currentColor"
                d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

export default VideoCard;
