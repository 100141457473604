import { useEffect, useState } from "react";
import DrawerMenu from "./DrawerMenu/DrawerMenu";
import Header from "./Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import Breadcrumbs from "./Breadcrumbs";
import AsideNav from "./AsideNav";
import http from "../utils/http.utils";
import Loader from "./Loader";
import Pagination from "./Pagination/Pagination";
import TrashIcon from "../assets/icons/trash.svg";
import toastr from "../utils/toast.utils";
import Backdrop from "./Backdrop";
import Input from "./Inputs/Input";

export default function UsersList() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const locale = useLocale();

  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [search, setSearch] = useState("");

  const handleNav = (item) => {
    if (item) {
      const params = new URLSearchParams(searchParams);
      params.set("nav", item.id);
      navigate(`/?${params.toString()}`);
    } else {
      navigate(`/${locale}/`);
    }
  };

  const getUsers = async () => {
    try {
      const response = await http.get("/api/users", {
        page,
        "order[email]": "asc",
        email: search,
      });
      setUsers(response["hydra:member"]);
      setTotalCount(response["hydra:totalItems"]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const addUser = async (e) => {
    setFormIsLoading(true);
    const data = new FormData(e.target);
    const email = data.get("email");
    try {
      await http.post("/api/users", {
        email,
      });
      toastr.success("User created");
      e.target.reset();
      setOpenModal(false);
      if (page !== 1) {
        setPage(1);
      } else {
        getUsers();
      }
    } catch (e) {
      toastr.error(e["hydra:description"]);
    } finally {
      setFormIsLoading(false);
    }
  };

  const deleteUser = async (e) => {
    try {
      await http.delete(`/api/users/${itemToDelete.id}`);
      toastr.success("User deleted");
      setItemToDelete(false);
      if (page !== 1) {
        setPage(1);
      } else {
        getUsers();
      }
    } catch (e) {
      console.log(e);
      toastr.error(e["hydra:description"]);
    }
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  return (
    <main className="h-full flex flex-col">
      <Header
        drawerIsOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <div className="grow flex overflow-hidden">
        <DrawerMenu
          isOpen={openDrawer}
          setIsOpen={setOpenDrawer}
          handleNav={handleNav}
        />
        <div className="w-full">
          <hr />
          <div className="m-4 flex justify-between items-end md:items-center">
            <div>
              <div className="mb-2">
                <Breadcrumbs items={[{ name: "stats" }]} showTitle />
              </div>
            </div>
          </div>
          <hr />
          <section className="flex w-full">
            <AsideNav />
            <div className="w-full p-10 flex flex-col">
              <div className="flex gap-4 items-center">
                <h3 className="col-span-2 text-2xl uppercase font-lacosteExpandedBold">
                  Users
                </h3>
                <button
                  onClick={() => setOpenModal(true)}
                  type="button"
                  className="text-primary bg-white border border-primary focus:outline-none hover:bg-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 light:bg-gray-800 light:text-white light:border-gray-600 light:hover:bg-gray-700 light:hover:border-gray-600"
                >
                  + User
                </button>
                {openModal && <Backdrop onClick={() => setOpenModal(false)} />}
                <dialog
                  open={openModal}
                  className="w-96 p-6 bg-white rounded-lg z-50"
                >
                  <div className="flex justify-between mb-2">
                    <h3>New user</h3>
                    <button onClick={() => setOpenModal(false)}>X</button>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      addUser(e);
                    }}
                  >
                    <label htmlFor="email-input">Email*</label>
                    <input
                      id={"email-input"}
                      required
                      type="email"
                      name={"email"}
                      placeholder="Email"
                      className="w-full border border-gray-300 rounded-lg p-2 mt-2 outline-none"
                    />
                    <div className="flex justify-center">
                      <button
                        className="text-white bg-primary border border-primary focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 me-2 mt-3 light:bg-gray-800 light:text-white light:border-gray-600 light:hover:bg-gray-700 light:hover:border-gray-600"
                        type={"submit"}
                      >
                        {formIsLoading ? (
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </dialog>
              </div>

              <div className="flex flex-col gap-2">
                <section className="flex justify-between gap-2">
                  <form className="flex w-full max-w-64 items-end">
                    <Input
                      id="search"
                      type="text"
                      placeholder="Search by email"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          getUsers();
                        }
                      }}
                      className={"h-10"}
                    />
                  </form>
                  {totalCount > 20 && (
                    <Pagination
                      page={page}
                      setPage={setPage}
                      total={totalCount}
                    />
                  )}
                </section>

                {isLoading ? (
                  <div className="w-full flex justify-center">
                    <Loader />
                  </div>
                ) : users.length > 0 ? (
                  <div
                    style={{
                      height: "calc(100dvh - 370px)",
                      overflowY: "auto",
                    }}
                  >
                    <table className="w-full">
                      <tbody>
                        {users.map((user, idx) => (
                          <tr
                            key={user.id}
                            className={`${
                              idx % 2 === 0 ? "bg-primary-100" : ""
                            }`}
                          >
                            <td className="pl-4 py-2">{user.email}</td>
                            <td className="py-2 pr-2 text-end">
                              <button onClick={() => setItemToDelete(user)}>
                                <img
                                  className="h-[24px] w-[24px]"
                                  src={TrashIcon}
                                  alt="Delete"
                                />{" "}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>No results.</p>
                )}
              </div>
              {itemToDelete && (
                <Backdrop onClick={() => setItemToDelete(null)} />
              )}
              <dialog
                open={!!itemToDelete}
                className="w-96 p-6 bg-white rounded-lg z-50"
              >
                <h3>
                  Are you sure you want to delete {" "}
                  <strong>{itemToDelete?.email}</strong> ?
                </h3>
                <div className="flex gap-2 justify-end">
                  <button
                    type="button"
                    onClick={() => setItemToDelete(null)}
                    className="text-primary bg-white border border-primary focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 me-2 mt-3 light:bg-gray-800 light:text-white light:border-gray-600 light:hover:bg-gray-700 light:hover:border-gray-600"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteUser()}
                    className="text-white bg-primary border border-primary focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 me-2 mt-3 light:bg-gray-800 light:text-white light:border-gray-600 light:hover:bg-gray-700 light:hover:border-gray-600"
                  >
                    Yes
                  </button>
                </div>
              </dialog>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
