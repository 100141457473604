import PropTypes from "prop-types";

const Card = ({ title, image, actions }) => {
  return (
    <div className="border flex flex-col gap-2 items-center">
      <div className="w-full h-40 flex justify-center items-center bg-primary-400 border border-primary">
        <div className="h-full w-full flex justify-center items-center">
          {image ? (
            <img
              src={image}
              alt={title}
              className="w-full h-full object-cover"
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <title>PDF</title>
              <path
                fill="currentColor"
                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-9.5 8.5c0 .8-.7 1.5-1.5 1.5H7v2H5.5V9H8c.8 0 1.5.7 1.5 1.5zm5 2c0 .8-.7 1.5-1.5 1.5h-2.5V9H13c.8 0 1.5.7 1.5 1.5zm4-3H17v1h1.5V13H17v2h-1.5V9h3zm-6.5 0h1v3h-1zm-5 0h1v1H7z"
              />
            </svg>
          )}
        </div>
      </div>
      <p className="text-center truncate uppercase  max-w-full px-2">
        {title}
      </p>
      <div className="p-2">{actions}</div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  actions: PropTypes.node,
};

export default Card;
