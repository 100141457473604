import NavBloc from "../NavBloc";

const NavGridView = ({ navs }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 px-page">
      {navs.map((nav, i) => (
        <NavBloc
          key={i}
          item={{
            ...nav,
            color: localStorage.getItem("color") ?? null,
          }}
        />
      ))}
    </div>
  );
};

export default NavGridView;
