import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import translateKey from "../../utils/translateKey";

export const MenuToggle = ({
  title,
  items = [],
  button,
  open = false,
  onClick,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClick = () => {
    setIsOpen(!isOpen);

    if (onClick) {
      onClick();
    }
  };

  return (
    <div {...props}>
      {button ? (
        <div onClick={handleClick}>{button}</div>
      ) : (
        <div
          className="flex justify-between items-center gap-2 cursor-pointer p-4 hover:bg-primary-100"
          onClick={handleClick}
        >
          <span className="font-bold font-lacosteExpandedBold uppercase">
            {title}
          </span>
          <span className={isOpen ? undefined : "rotate-180"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g
                id="feArrowUp0"
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
              >
                <g id="feArrowUp1" fill="currentColor">
                  <path id="feArrowUp2" d="m4 15l8-8l8 8l-2 2l-6-6l-6 6z" />
                </g>
              </g>
            </svg>
          </span>
        </div>
      )}
      <ul className={isOpen ? "block" : "hidden"}>
        {items.map((item, key) => (
          <li
            key={item.id}
            onClick={() => item.onClick()}
            className={`px-8 py-2 text-secondary cursor-pointer hover:bg-primary-light hover:font-bold hover:text-black transition duration-75 ${
              key === items.length - 1 && "mb-2"
            }`}
          >
            {translateKey(item.names)}
          </li>
        ))}
      </ul>
    </div>
  );
};

MenuToggle.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  button: PropTypes.node,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MenuToggle;
