import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enKeys from "./en_US.json";
import frKeys from "./fr_FR.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: ["en_US", "fr_FR"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en_US: {
        translation: enKeys,
      },
      fr_FR: {
        translation: frKeys,
      },
    },
  });

export default i18n;
