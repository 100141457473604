import { useTranslation } from "react-i18next";
import http from "../utils/http.utils";
import { isMobile, isTablet } from "../utils/style.utils";
import { useEffect, useRef, useState } from "react";
import translateKey from "../utils/translateKey";

export default function Banner() {
    const { t } = useTranslation();

    const [wrapWidth, setWrapWidth] = useState(0);
    const [offset, setOffset] = useState(0);
    const wrapRef = useRef(null);
    const [id, setId] = useState(0);
    const [messages, setMessages] = useState(null);

    const fetchMessages = async () => {
        const data = await http.get("/api/flat-contents", {
            pagination: false,
            breadcrumb: `${process.env.REACT_APP_CX_ROOT_ID}/${process.env.REACT_APP_MESSAGES_FOLDER_ID}`,
        });
        setMessages(data["hydra:member"]);
    };

    useEffect(() => {
        void fetchMessages();
    }, []);

    useEffect(() => {
        const wrapWidth = isMobile() || isTablet() ? window.screen.width * 3 : window.screen.width;
        setWrapWidth(wrapWidth);

        const move = () => {
            const requestId = window.requestAnimationFrame(() => {
                setOffset((offset) => {
                    if (-offset === Math.floor(wrapWidth)) {
                        return -offset;
                    }
                    return offset - 1;
                });
                setId(requestId);
                move();
            });
        };

        move();

        return () => {
            window.cancelAnimationFrame(id);
        };
    }, []);

    return (
        <div className="shrink-0 overflow-hidden bg-primary p-1">
            <div
                ref={wrapRef}
                className={`relative h-12 bg-b flex items-center flex-nowrap ${id ? "visible" : "hidden"}`}
                style={{
                    width: `${wrapWidth}px`,
                    transform: `translateX(${offset}px)`,
                }}
            >
                {messages ? (
                    messages.map((msg, i) => (
                        <div key={msg.id} className="flex items-center m-2 gap-2 float-left bg-transparent text-white font-lacosteExpandedBold break-normal md:text-xl whitespace-nowrap">
                            <span className="whitespace-nowrap">{translateKey(msg.names)}</span>
                            {i < messages.length - 1 && <span> | </span>}
                        </div>
                    ))
                ) : (
                    <div className="flex items-center m-2 gap-2 float-left bg-transparent text-white font-lacosteExpandedBold break-normal md:text-xl">
                        <span>{t("app_name")} | No message to show</span>
                    </div>
                )}
            </div>
        </div>
    );
}
