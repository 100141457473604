const LeftDrawer = ({ isOpen, setIsOpen, children }) => {
  return (
    <div
      className={`${
        isOpen
          ? "absolute lg:relative top-0 z-[100] border h-full lg:h-auto"
          : "invisible"
      }`}
    >
      <div
        className={`${
          isOpen ? "w-screen sm:w-96" : "w-0"
        } h-full left-0 transition-all duration-200 transform`}
      >
        {children}
      </div>
    </div>
  );
};

export default LeftDrawer;
