import localeUtils from './locale.utils';

export default function translateKey(key) {
    if (!key) {
        return null;
    }

    // search for the current locale
    const locale = localeUtils.getLocale();
    if (key.hasOwnProperty(locale)) {
        return key[locale];
    }

    // workaround for en_US (en_EN)
    if ('en_US' === locale && key.hasOwnProperty('en_EN')) {
        return key['en_EN'];
    }

    // fallback fr_FR (sadly, was the default in the platform before)
    if (key.hasOwnProperty('fr_FR')) {
        return key['fr_FR'];
    }

    // no hope…
    return Object.values(key)?.[0] || null;
}
