const Ref = ({ sku }) => {
  return (
    <div>
      {sku.oldRef && (
        <span className="text-red-500 font-bold">
          {sku.oldRef}
          {" > "}
        </span>
      )}
      <span className={`${sku.oldRef && "font-bold"}`}>{sku.ref}</span>
    </div>
  );
};

export default Ref;
