import PropTypes from "prop-types";

const MediaData = ({ data }) => {
  return (
    <>
      <div className="flex justify-center items-center flex-col gap-2">
        {data.media && (
          <div>
            <img
              src={`${process.env.REACT_APP_BACK_URL}${data.media}/binary`}
              alt={data.title}
            />
          </div>
        )}
      </div>
      <div className="text-left  py-2">
        <div className="text-secondary font-lacosteExpandedBold">
          {data.ref || data.sku}
        </div>
        <div className="font-lacosteExpandedBold"> {data.title} </div>
        <div dangerouslySetInnerHTML={{ __html: data.text }} />
      </div>
      <hr className="my-2" />
    </>
  );
};

MediaData.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.string,
    ref: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default MediaData;
