import React from "react";

export default function Pagination({ page, setPage, total }) {
  const firstNumber = (page - 1) * 20 + 1;
  const lastNumber = page * 20 > total ? total : page * 20;
  const totalPages = Math.ceil(total / 20);
  return (
    <div className="flex flex-col">
      <span className="text-sm text-gray-700 light:text-gray-400">
        Showing{" "}
        <span className="font-semibold text-gray-900 light:text-white">
          {firstNumber}
        </span>{" "}
        to{" "}
        <span className="font-semibold text-gray-900 light:text-white">
          {lastNumber}
        </span>{" "}
        of{" "}
        <span className="font-semibold text-gray-900 light:text-white">
          {total}
        </span>{" "}
        Entries
      </span>
      <div className="inline-flex mt-2 xs:mt-0 justify-end">
        <button
          type="button"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
          className="flex items-center justify-center px-3 h-8 text-sm font-medium text-primary bg-white rounded-s border border-primary"
        >
          <svg
            className="w-3.5 h-3.5 me-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 5H1m0 0 4 4M1 5l4-4"
            />
          </svg>
          Prev
        </button>
        <button
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
          className="flex items-center justify-center px-3 h-8 text-sm font-medium text-primary bg-primar border-s rounded-e  border border-primary"
        >
          Next
          <svg
            className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
