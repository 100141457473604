import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

const MediaCover = ({ media, hasData, onClick }) => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);

  const color = window.localStorage.getItem("color") ?? "#000000";

  useEffect(() => {}, [loaded]);

  return (
    <div
      className="text-center mx-2 grow flex justify-center sm:block"
      onClick={() => onClick(media)}
      style={{
        maxHeight: `calc(100dvh - 183px)`,
      }}
    >
      <div className="flex flex-col items-center">
        <div className="flex justify-center overflow-hidden relative">
          <img
            src={media.image}
            alt={media.name}
            onLoad={() => setLoaded(true)}
            id={`media-cover-${media.id}`}
            className="object-scale-down lg:hover:scale-110 transition duration-200 cursor-pointer"
            style={{
              height: `calc(100dvh - 183px - 5rem)`,
            }}
          />
          {hasData && (
            <div className="absolute top-0 mx-auto">
              <button
                className="flex justify-center items-center bg-primary py-2 px-6 z-90 rounded"
                style={{ color }}
                type="button"
              >
                <span className="font-lacosteExpandedBold uppercase text-xs text-white">
                  {t("details")}
                </span>
              </button>
            </div>
          )}
          {!loaded ? <Loader /> : null}
        </div>
        <p className="font-lacosteExpandedBold text-center text-base">
          {media.name}
        </p>
      </div>
    </div>
  );
};

MediaCover.propTypes = {
  media: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
  }),
  hasData: PropTypes.bool,
  index: PropTypes.number,
  total: PropTypes.number,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
};

export default MediaCover;
