import tailwindConfig from 'tailwindcss/defaultConfig';
import resolveConfig from 'tailwindcss/resolveConfig';

export const getTailwindConfig = () => {
    return resolveConfig(tailwindConfig);
};

export const getPrimaryColor = () => {
    return getTailwindConfig().theme.colors.primary;
};

export const isMobile = () => {
    return (
        window.innerWidth <
        parseInt(getTailwindConfig().theme.screens.md.split('px')[0])
    );
};

export const isTablet = () => {
    return (
        window.innerWidth <
        parseInt(getTailwindConfig().theme.screens.lg.split('px')[0])
    );
};

export const isDesktop = () => {
    return (
        window.innerWidth >
        parseInt(getTailwindConfig().theme.screens.lg.split('px')[0])
    );
};

export const hexToRgba = (hex, opacity) => {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    const rgba = [r, g, b].map((item) => {
        if (isNaN(item)) {
            return 0;
        }
        return item;
    });

    r = rgba[0];
    g = rgba[1];
    b = rgba[2];

    if (opacity) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
    }

    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
};
