import toast from "react-hot-toast";

class Toastr {
  success(message) {
    toast.success(message, {
      style: {
        border: "1px solid #00482f",
        borderRadius: 0,
      },
      iconTheme: {
        primary: "#00482f",
        secondary: "#fffaee",
      },
    });
  }

  error(message) {
    toast.error(message, {
      style: {
        border: "1px solid #00482f",
        borderRadius: 0,
      },
      iconTheme: {
        secondary: "#fffaee",
      },
    });
  }
}

const toastr = new Toastr();

export default toastr;
