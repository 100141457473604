import { useEffect, useState } from "react";
import DrawerMenu from "./DrawerMenu/DrawerMenu";
import Header from "./Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import Breadcrumbs from "./Breadcrumbs";
import AsideNav from "./AsideNav";
import http from "../utils/http.utils";
import Loader from "./Loader";
import Input from "./Inputs/Input";

export default function AdminStats() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const locale = useLocale();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [loginStats, setLoginStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleNav = (item) => {
    if (item) {
      const params = new URLSearchParams(searchParams);
      params.set("nav", item.id);
      navigate(`/?${params.toString()}`);
    } else {
      navigate(`/${locale}/`);
    }
  };

  const currentDate = new Date();
  const firstDayCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const formatDate = (date) => date.toISOString().split("T")[0];

  const start = searchParams.get("start") || formatDate(firstDayCurrentMonth);
  const end = searchParams.get("end") || formatDate(lastDayCurrentMonth);

  const [range, setRange] = useState({
    start: start,
    end: end,
  });

  const fetchLoginStats = async () => {
    setIsLoading(true);
    if (!range.start || !range.end) {
      setLoginStats([]);
      return;
    }

    try {
      const response = await http.get("/api/login-stats", range);
      setLoginStats(response["hydra:member"]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchLoginStats();
  }, []);

  const sum = loginStats.reduce((acc, item) => acc + item.count, 0);

  return (
    <main className="h-full flex flex-col">
      <Header
        drawerIsOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <div className="grow flex overflow-hidden">
        <DrawerMenu
          isOpen={openDrawer}
          setIsOpen={setOpenDrawer}
          handleNav={handleNav}
        />
        <div className="w-full">
          <hr />
          <div className="m-4 flex justify-between items-end md:items-center">
            <div>
              <div className="mb-2">
                <Breadcrumbs items={[{ name: "stats" }]} showTitle />
              </div>
            </div>
          </div>
          <hr />
          <section className="flex w-full">
            <AsideNav />
            <div className="w-full p-10 flex flex-col gap-4">
              <h3 className="col-span-2 text-2xl uppercase font-lacosteExpandedBold">
                Stats
              </h3>

              <div className="flex flex-col gap-2">
                <form
                  className="flex gap-1 w-full lg:w-1/2"
                  onSubmit={(e) => {
                    e.preventDefault();

                    const params = new URLSearchParams(searchParams);
                    params.set("start", range.start);
                    params.set("end", range.end);

                    navigate(`/${locale}/stats?${params.toString()}`);

                    void fetchLoginStats();
                  }}
                >
                  <Input
                    type="date"
                    value={range.start}
                    onChange={(e) =>
                      setRange({ ...range, start: e.target.value })
                    }
                    max={range.end}
                  />
                  <Input
                    type="date"
                    value={range.end}
                    onChange={(e) =>
                      setRange({ ...range, end: e.target.value })
                    }
                    min={range.start}
                  />
                  <button
                    type="submit"
                    className="flex justify-center items-center gap-2 border py-2 w-full hover:bg-slate-50 transition uppercase"
                  >
                    Filter
                  </button>
                </form>

                {isLoading ? (
                  <div className="w-full flex justify-center">
                    <Loader />
                  </div>
                ) : loginStats.length > 0 ? (
                  <div
                    style={{
                      height: "calc(100dvh - 340px)",
                      overflowY: "auto",
                    }}
                  >
                    <table className="w-full">
                      <tbody>
                        {loginStats.map((stat, idx) => (
                          <tr
                            key={stat.id}
                            className={`${
                              idx % 2 === 0 ? "bg-primary-100" : ""
                            }`}
                          >
                            <td className="pl-4 py-1">{stat.email}</td>
                            <td className="pr-4 text-right">{stat.count}</td>
                          </tr>
                        ))}
                        <tr>
                          <td className="pl-4 py-1">Total</td>
                          <td className="pr-4 text-right">{sum}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>No results.</p>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
