import { useNavigate } from "react-router";
import http from "../utils/http.utils";
import { useEffect } from "react";
import { useLocale } from "../context/LocaleContext";

export default function LogoutPage() {
  const navigate = useNavigate();
  const locale = useLocale();

  useEffect(() => {
    http.setToken(null);
    navigate(`/${locale}/login`);
  }, []);

  return <div />;
}
