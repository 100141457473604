import ImageBackground from "../assets/images/bg-login.jpg";
import { useTranslation } from "react-i18next";
import Input from "../components/Inputs/Input";
import http from "../utils/http.utils";
import toastr from "../utils/toast.utils";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import Loader from "../components/Loader";
import { useLocale } from "../context/LocaleContext";

export default function ForgotPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useLocale();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const resetPass = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await http.post("/api/password-reset-starts", { email });
    } catch (error) {
      console.error("Password reset failed:", error);
      toastr.error(t("password_reset_failed"));
    } finally {
      setLoading(false);
      toastr.success(t("password_reset_started"));
      navigate(`/${locale}/logout`);
    }
  };

  return (
    <section
      className="h-full"
      style={{
        backgroundImage: `url(${ImageBackground})`,
        backgroundSize: "cover",
      }}
    >
      <div className="h-full flex justify-center items-center">
        <div className="bg-white p-10 flex flex-col items-center justify-center gap-2 w-[400px]">
          <Logo />
          <h1 className="uppercase font-lacosteExpandedBold mb-10">
            {t("app_name")}
          </h1>
          <h2 className="self-start">{t("forgot_password")}</h2>
          <p className="text-sm text-secondary mb-6">
            {t("forgot_password_help")}
          </p>
          {loading ? (
            <Loader />
          ) : (
            <form
              onSubmit={resetPass}
              className="w-full flex flex-col gap-4 font-lacosteExpandedBold"
            >
              <div>
                <label>{t("email")}</label>
                <Input
                  type={"email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="flex justify-center items-center gap-2 border py-2 w-full hover:bg-slate-50 transition uppercase"
              >
                {t("send")}
              </button>
              <div className="flex justify-center items-center w-full">
                <hr className="w-3/4 flex justify-center" />
              </div>
              <div className="flex justify-center w-full">
                <Link
                  to="/login"
                  className="text-sm hover:underline hover:cursor-pointer "
                >
                  {t("cancel")}
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
}
