import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import toastr from "../utils/toast.utils";
import Loader from "../components/Loader";
import http from "../utils/http.utils";

export default function NotFound() {
  const navigate = useNavigate();
  const locale = useLocale();
  // console.log("pathname", window.location.pathname);

  useEffect(() => {
    toastr.error("Page not found");
    if (http.getToken()) {
      navigate(`/${locale}/`);
    } else {
      navigate(`${locale}/login`);
    }
  }, []);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center ">
      <Loader />
      <p>Page not found. Redirecting to login...</p>
    </div>
  );
}
