import DrawerMenu from "../components/DrawerMenu/DrawerMenu";
import Header from "../components/Header/Header";
import http from "../utils/http.utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import Breadcrumbs from "../components/Breadcrumbs";
import Loader from "../components/Loader";

export default function SlugPage() {
  const { t } = useTranslation();
  const locale = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location);
  const params = useParams();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [staticContent, setStaticContent] = useState(null);

  const fetchStaticContent = async () => {
    const data = await http.get(
      `/api/flat-contents/${
        params.slug === "contact"
          ? process.env.REACT_APP_CONTACT_ID
          : process.env.REACT_APP_CALENDAR_ID
      } `
    );
    const media = data.values.attribute_Fichier?.[0].data.url;
    const content = data.values.content_description?.[1].data;
    setStaticContent({ ...data, media, content });
  };

  useEffect(() => {
    fetchStaticContent();
  }, [params.slug]);

  const handleNav = (item) => {
    if (item) {
      const params = new URLSearchParams(searchParams);
      params.set("nav", item.id);
      navigate(`/${locale}/?` + params.toString());
    } else {
      navigate(`/${locale}/`);
    }
  };

  return (
    <div className="h-full flex flex-col">
      <Header
        drawerIsOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <div className="grow flex overflow-hidden">
        <DrawerMenu
          isOpen={openDrawer}
          setIsOpen={setOpenDrawer}
          handleNav={handleNav}
        />
        <div className="w-full">
          <hr />
          <div className="m-4 flex justify-between items-end md:items-center">
            <div>
              <div className="mb-2">
                <Breadcrumbs items={[{ name: t(params.slug) }]} showTitle />
              </div>
            </div>
          </div>
          <hr />
          {staticContent ? (
            <>
              {staticContent.content && (
                <div className="p-20 flex justify-center">
                  <div
                    dangerouslySetInnerHTML={{ __html: staticContent.content }}
                  />
                </div>
              )}
              {staticContent.media && (
                <object data={staticContent.media} className="w-full h-full">
                  <p>
                    {t("unable_to_display_file")} :{" "}
                    <a href={staticContent.media}>{t("download")}</a>
                  </p>
                </object>
              )}
            </>
          ) : (
            <div className="p-20 flex justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
