import localeUtils from "./locale.utils";

class Http {
  constructor() {
    this.token = null;
  }

  setToken(token) {
    if (token) {
      this.token = token;
      localStorage.setItem("token", token);
    } else {
      this.token = null;
      localStorage.clear();
    }
  }

  getToken() {
    return this.token || localStorage.getItem("token");
  }

  async fetch(uri, config = {}) {
    const headers = config.headers || {};
    headers["Content-Type"] = "application/json";
    const token = this.getToken();
    token && (headers["X-Sinfin-Token"] = token);
    config.headers = headers;

    const isJson = (rct) =>
      [
        "application/json",
        "application/ld+json",
        "application/problem+json",
      ].some((bct) => rct?.startsWith(bct));

    // clean response
    const response = await fetch(
      `${process.env.REACT_APP_BACK_URL}${uri}`,
      config
    );
    const locale = localeUtils.getLocale();
    const contentType = response.headers.get("Content-Type");
    if ([401, 403].includes(response.status)) {
      if (token) {
        this.setToken(null);
        document.location.href = `/${locale}/logout-force?t=${new Date().getTime()}`;
        return;
      }
      if (isJson(contentType)) {
        throw await response.json();
      }

      throw response;
    }
    if ([404, 422].includes(response.status)) {
      if (isJson(contentType)) {
        throw await response.json();
      }

      throw response;
    }

    if (config.method === "DELETE") {
      return response;
    }

    if (!isJson(contentType)) {
      this.setToken(null);
      document.location.href = `/${locale}/logout-force?t=${new Date().getTime()}`;
      return;
    }

    // renew token
    const renew = response.headers.get("X-Sinfin-Token-Renew");
    if (renew) {
      this.setToken(renew);
    }

    return await response.json();
  }

  async get(uri, params = {}) {
    const query = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");

    return this.fetch(`${uri}?${query}`);
  }

  async post(uri, data = {}) {
    return await this.fetch(uri, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async put(uri, data = {}) {
    return await this.fetch(uri, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async delete(uri) {
    return await this.fetch(uri, {
      method: "DELETE",
    });
  }
}

const http = new Http();

export default http;
