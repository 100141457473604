import logout_icon from "../../assets/icons/deconnexion.svg";
import contact_icon from "../../assets/icons/picto-messagerie.svg";
import { MenuToggle } from "../../components/MenuToggle";
import http from "../../utils/http.utils";
import { isMobile } from "../../utils/style.utils";
import { getChildrens } from "../../utils/tree.utils";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import LeftDrawer from "./LeftDrawer";
import DrawerTab from "./DrawerTab";
import HeaderDrawer from "./HeaderDrawer";
import toastr from "../../utils/toast.utils";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useLocale } from "../../context/LocaleContext";
import translateKey from "../../utils/translateKey";

const DrawerMenu = ({ isOpen, setIsOpen, handleNav }) => {
  const navigate = useNavigate();
  const [tabOpen, setTabOpen] = useState(null);
  const [tabs, setTabs] = useState(null);
  const { t } = useTranslation();
  const locale = useLocale();

  const handleClick = (tab) => {
    if (tab) {
      setTabOpen(tabOpen === tab.id ? null : tab.id);
    } else {
      setTabOpen(null);
      handleNav();
    }
  };

  const handleClickChildren = (item) => {
    if (isMobile()) {
      setIsOpen(false);
    }
    handleNav(item);
  };

  const fetchAllNavs = async () => {
    const data = await http.get("/api/flat-content-categories", {
      pagination: false,
      // breadcrumb: `${process.env.REACT_APP_CX_ROOT_ID}/${process.env.REACT_APP_CX_ID}`,
      // "breadcrumbDepth[lte]": 5,
    });
    const dataFiltered = data["hydra:member"]
      .filter(
        (item) =>
          item.parent ===
          `/api/flat-content-categories/${process.env.REACT_APP_CX_ID}`
      )
      .map((item) => ({
        ...item,
        items: getChildrens(item.id, data["hydra:member"]),
      }));
    setTabs(dataFiltered);
  };

  useEffect(() => {
    fetchAllNavs();
  }, []);

  // Synchro Logic
  const [synchro, setSynchro] = useState(null);
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const data = await http.get("/api/users/0");
      setUser(data);
    } catch (error) {
      navigate(`/${locale}/logout`);
    }
  };

  const createSynchronization = async () => {
    const response = await http.post("/api/synchronizations");
    if (425 === response?.status) {
      toastr.error(response.detail);
    } else {
      toastr.success(t("sync_created"));
      fetchSynchronization();
    }
  };

  const fetchSynchronization = async () => {
    const data = await http.get("/api/synchronizations", {
      limit: 1,
      "order[createdAt]": "desc",
    });
    setSynchro(data["hydra:member"][0]);
  };

  const formatSynchroMessage = (synchro) => {
    if (!synchro) {
      return null;
    }

    let message = synchro.email ? "synced_by" : "synced_auto";
    let date = synchro.createdAt;

    if (synchro.endedAt) {
      message += "_ended";
      date = synchro.endedAt;
    } else if (synchro.startedAt) {
      message += "_started";
      date = synchro.startedAt;
    }

    date = new Date(date).toLocaleString();

    return (
      <p className="text-xs text-secondary">
        {t(message).replace("%date%", date).replace("%email%", synchro.email)}
      </p>
    );
  };

  useEffect(() => {
    fetchUser();
    fetchSynchronization();

    // calling user every 10 minutes (ensure token wil be ok)
    const id = setInterval(() => fetchUser(), 10 * 60 * 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <LeftDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={`${isOpen ? "flex flex-col" : "hidden"} bg-white h-full`}>
        <HeaderDrawer
          drawerIsOpen={isOpen}
          toggleDrawer={() => setIsOpen(false)}
        />
        <div className="flex flex-col justify-between h-full">
          <div>
            {!tabs ? (
              <Loader />
            ) : (
              <>
                <DrawerTab
                  label={t("home")}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <title>Home Icon</title>
                      <path
                        fill="currentColor"
                        d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8z"
                      />
                    </svg>
                  }
                  onClick={() => navigate(`/${locale}/`)}
                />
                {tabs.map((tab) =>
                  tab.items.length > 0 ? (
                    <div key={tab.id} className="border-b">
                      <MenuToggle
                        title={translateKey(tab.names)}
                        items={tab.items.map((c) => ({
                          ...c,
                          onClick: () => handleClickChildren(c),
                        }))}
                        open={tabOpen === tab.id}
                        onClick={() => handleClick(tab)}
                      />
                    </div>
                  ) : (
                    <DrawerTab
                      key={tab.id}
                      label={translateKey(tab.names)}
                      onClick={() => handleClick(tab)}
                    />
                  )
                )}
              </>
            )}
            <DrawerTab
              label={t("contact")}
              icon={<img src={contact_icon} alt="contact_icon" />}
              className="font-lacosteExpandedBold gap-4 text-black md:hidden bg-primary-light"
              onClick={() => navigate(`/${locale}/contact`)}
            />
            <DrawerTab
              label={t("logout")}
              icon={<img src={logout_icon} alt="logout_icon" />}
              className="font-lacosteExpandedBold gap-4 text-black md:hidden bg-primary-light"
              onClick={() => navigate(`/${locale}/logout`)}
            />
            {/* <div className="lg:hidden p-4">
                            <LangSwitcher />
                        </div> */}
          </div>
          <div className="flex flex-col justify-end items-center p-2 gap-2">
            <Link
              to={`/${locale}/profile`}
              className="px-2 flex gap-2 justify-center items-center hover:bg-primary hover:text-white transition-colors uppercase text-primary font-lacosteExpandedBold border w-full py-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <title>Profile Icon</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                />
              </svg>
              {user?.email}
            </Link>
            {user?.isAdmin ? (
              <>
                <button
                  type="button"
                  onClick={createSynchronization}
                  className="hover:bg-primary hover:text-white transition-colors uppercase text-primary font-lacosteExpandedBold border w-full py-2"
                >
                  {t("sync_create")}
                </button>
                <span>{formatSynchroMessage(synchro)}</span>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </LeftDrawer>
  );
};

DrawerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  handleNav: PropTypes.func.isRequired,
};

export default DrawerMenu;
