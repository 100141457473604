import { useState, useRef } from "react";
import useClickOut from "../../utils/hooks/useClickOut";
import DropdownItem from "./DropdownItem";
import PropTypes from "prop-types";

const Dropdown = ({ button, items, noItemText, onToggle, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOut(wrapperRef, () => {
    setIsOpen(false);
  });

  const handleClick = () => {
    setIsOpen(!isOpen);

    if (onToggle) {
      onToggle();
    }
  };

  return (
    <div className="relative inline-block text-left" ref={wrapperRef}>
      <div>
        <div className="cursor-pointer" onClick={handleClick}>
          {button}
        </div>
      </div>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-64 md:w-96 max-h-96 overflow-y-auto origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {children && children}

          {items?.length > 0 && (
            <ul role="none">
              {items.map((item, i) => (
                <DropdownItem
                  key={i}
                  id={item.id}
                  content={item.content}
                  onClick={(e) => item.onClick(e)}
                />
              ))}
            </ul>
          )}
          {items?.length === 0 && <p className="font-bold p-4">{noItemText}</p>}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  button: PropTypes.node,
  items: PropTypes.array,
  noItemText: PropTypes.string,
  onToggle: PropTypes.func,
  children: PropTypes.node,
};

export default Dropdown;
