import React, { useEffect } from "react";
import "./pdf.css";
import { useTranslation } from "react-i18next";

const Pdf = () => {
  const data = window.data;
  const { t } = useTranslation();

  if (!window.data) {
    window.location.href = `/`;
  }

  useEffect(()=>{
    if (data) {
      window.print()
    }
  },[data])

  return (
    <>
      {data.refGroups.length > 0 && (
        <>
          <div className="header-container-refs">
            <div className="header-info-refs">
              <p className="header-name-refs">{data.name}</p>
            </div>
            <div className="img-container-refs">
              <img className="header-img-refs" src={data.image} alt={data.name} />
            </div>
          </div>
          <div className="refs-container">
            <div className="infos-refs-container">
              <p className="information-refs-title">
                {t("information")}
              </p>
              <p
                className="item-text-refs"
                dangerouslySetInnerHTML={{ __html: data.info }}
              />
            </div>
            <div className="subref-container">
              <p className="references-refs-title">
                {t("references")}
              </p>
              {data.refGroups.map((item, index) => (
                  <div key={index} className="data-item-refs">
                      <p className="item-title-refs">{item.title}</p>
                      <div>
                        {item.products.map((product, index) => (
                          <p key={index} className="product-ref">{product}</p>
                        ))}
                      </div>
                      <p className="item-subref-refs">{item.subRef}</p>
                  </div>
              ))}
            </div>
          </div>
        </>
      )}
      {data.refGroups.length === 0 && (
        <>
          <div className="header-container">
            <div className="header-info">
              <p className="header-name">{data.name}</p>
              <p className="header-price">{data.price}</p>
            </div>
            <div className="img-container">
              <img className="header-img" src={data.image} alt={data.name} />
            </div>
          </div>
          <div>
            {data.data.map((item, index) => (
                <div key={index} className="data-item">
                  <div className="img-side">
                    <img
                      alt={item.title}
                      className="item-img"
                      src={`${process.env.REACT_APP_BACK_URL}${item.media}/binary`}
                    />
                  </div>
                  <div className="text-side">
                    <p className="item-sku">{item.sku}</p>
                    <p className="item-title">{item.title}</p>
                    <br />
                    <div
                      className="item-text"
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  </div>
                </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Pdf;
