const Input = ({
  id,
  type,
  value,
  onChange,
  className,
  disabled = false,
  required = false,
  ...props
}) => {
  return (
    <input
      id={id}
      type={type}
      value={value}
      onChange={onChange}
      disabled={disabled}
      required={required}
      className={`${className} w-full border-0 py-1.5 px-2 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:outline-none focus:ring-2 focus:ring-primary`}
      {...props}
    />
  );
};

export default Input;
