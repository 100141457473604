import PropTypes from "prop-types";
import React from "react";

const MenuToggleItem = ({ item, onClick, ...props }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };
  return (
    <li key={item.id} onClick={handleClick} {...props}>
      <span className="p-2 font-lacosteExpandedBold"> {item.name} </span>
    </li>
  );
};

MenuToggleItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MenuToggleItem;
