import Ref from "./Ref";

const RefGroup = ({ group }) => {
  const subRef = group.subRef;
  const refs = group.products.map((ref, i) => {
    return {
      ref,
      oldRef: subRef?.find((sub) => sub.sku === ref)?.oldRef ?? null,
    };
  });

  return (
    <div className="my-2">
      <div className="font-lacosteExpandedBold uppercase text-md text-xs">
        {group.title}
      </div>
      {refs?.map((ref, i) => (
        <Ref key={i} sku={ref} />
      ))}
    </div>
  );
};

export default RefGroup;
